<template>
 <div>
    <div class="my-5 text-center gap-2 flex">
        <input type="text" name="from" id="from" placeholder="از تاریخ" v-model="fromDate" class="border-0 rounded bg-grey-light px-2 py-2 text-black">
        <input type="text" name="from" id="from" placeholder="تا تاریخ" v-model="toDate" class="border-0 rounded bg-grey-light px-2 py-2 text-black">
        <button @click="getLogs(fromDate, toDate), isData = true" class="px-2 py-2 bg-secondary rounded border-0 text-white
        ">دریافت اطلاعات</button>
    </div>

    <div v-if="isData">
        <div class="flex text-white bg-primary py-4 px-4 rounded my-2 text-sm text-center" >
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> یوزر  </span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> نود </span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> آیدی ماشین </span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> شماره ماشین</span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> وضعیت</span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> دریافتی</span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> ارسالی </span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> هزینه </span>
            <span class="w-48 border-solid border-0 border-r border-white border-opacity-25 px-2"> تاریخ ساخت </span>

        </div>
        <div class="flex text-white bg-primary py-4 px-4 rounded my-2 text-sm text-center" :key="log" v-for="log in logs">
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> {{ log.user_id }} </span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> {{ log.node_id }} </span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> {{ log.vm_id }} </span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> {{ log.vm_number }} </span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> {{ log.vm_status }} </span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> {{ log.total_received }} </span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> {{ log.total_sent }} </span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> {{ log.traffic_cost }} </span>
            <span class="w-48 border-solid border-0 border-r border-white border-opacity-25 px-2"> {{ log.vm_created_at }} </span>

        </div>
    </div>
 </div>
</template>

<script>
    export default {

        data: () => ({
            fromDate:'',
            toDate:'',
            logs:[],
            isData:false
        }),

        methods: {
            getLogs(from, to) {
                 this.$http.post(
                    '/admin/cloud/traffic/all/logs', { log_status: 1, from_date: from, to_date: to }
                 ).then((res) => {
                    this.logs = res.data.params;
                 })
            }
        },

        created() {

        }

    }
</script>
