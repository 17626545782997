import { render, staticRenderFns } from "./TrafficLog.vue?vue&type=template&id=3a7c1067"
import script from "./TrafficLog.vue?vue&type=script&lang=js"
export * from "./TrafficLog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports